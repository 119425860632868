.pronunciation-holder {
  /* background-color: white; */
  position: absolute;
  height: 100%;
  top: 0px;
  /* background: transparent url(../assets/BG-Pronounciation.png) 0 0 no-repeat;
  background-size: 100%; */
}
.pronunciation-content-intro {
  /* background-color: white; */
  position: relative;
  /* width: 100%; */
  top: 0px;
  display: flex;
  /* background: transparent url(../assets/BG-Pronounciation.png) 0 0 no-repeat; */
  /* background-color: #461083; */

  background-size: 100%;
}

.pronunciation-left {
  display: flex;
  /* padding: 50px; */
  gap: 10px;
  flex-direction: column;
  /* justify-content: flex-start; */
  align-items: center;
  margin-top: 50px;
  width: 100%;
}

.pronunciation-right {
  display: flex;
  /* padding: 50px; */
  gap: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* margin-top: 50px; */
  width: 100%;
}

.pronunciation-description {
  /* width: 650px; */
  /* height: 350px; */
  position: relative;
  /* left: 50px; */
  /* top: 600px; */
  font-size: large;
  line-height: initial;
  text-align: left;
  padding: 25px;
  border-radius: 10px;
  color: white;
}
.pronunciation-category-title {
  font-size: x-large;
  justify-content: center;
  /* padding: 40px; */
}
.pronunciation-category {
  display: flex;
  gap: 50px;
  flex-direction: row;
  font-size: x-large;
  color: white;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.btn-pronunciation-category {
  /* padding: 20px 150px; */
  background-color: #8f8fb8;
  border-radius: 50px;
  color: white;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  font-size: large;
  box-shadow: 0px 5px 0px 0px rgba(0, 0, 0, 0.3);
}

/* info */
.pronunciation-navigation-holder {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.pronunciation-map-holder {
  height: 100%;
  /* background-color: aqua; */
  width: 100%;
  gap: 10px;
  /* padding: 50px; */
}

.pronunciation-map-item {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 800px;
}

.pronunciation-category-description {
  font-size: large;
  line-height: initial;
  text-align: left;
  max-height: 300px;
  background-color: white;
  overflow: hidden;
  overflow-y: auto;
  padding: 20px;
  border-radius: 20px;
}

.pronunciation-category-name {
  font-size: x-large;
  font-weight: bold;
}

@media screen and (max-width: 600px) {
  .pronunciation-map-holder {
    padding: 5px;
  }

  .pronunciation-ledger-holder {
    width: 40px;
    height: 64%;
  }
}
