.perception-holder {
  /* background-color: white; */
  position: absolute;
  height: 100%;
  top: 0px;
  background-size: 100%;
}
.perception-content-intro {
  /* background-color: white; */
  position: relative;
  /* width: 100%; */
  top: 0px;
  display: flex;
  /* background: transparent url(../assets/BG-Pronounciation.png) 0 0 no-repeat; */
  /* background-color: #461083; */

  background-size: 100%;
}
.perception-right {
  display: flex;
  /* padding: 50px; */
  gap: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  width: 100%;
}

.perception-left {
  display: flex;
  /* padding: 50px; */
  gap: 10px;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  margin-top: 50px;
  width: 100%;
}
.perception-description {
  /* width: 650px; */
  /* height: 350px; */
  position: relative;
  /* left: 50px; */
  /* top: 600px; */
  font-size: large;
  line-height: initial;
  text-align: left;
  padding: 25px;
  border-radius: 10px;
  color: white;
}
.perception-category {
  position: relative;
  display: flex;
  gap: 20px;
  flex-direction: column;
  font-size: large;
  color: white;
  /* width: 100%; */
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
.btn-perception-category {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 20px;
  background-color: blue;
  border-radius: 50px;
  box-shadow: 0px 5px 0px 0px rgba(0, 0, 0, 0.3);
}

.perception-navigation-holder {
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
}

.perception-nav-btn {
  /* width: 200px; */
  border-radius: 50px;
  padding: 20px 20px;
  display: flex;
  height: 45px;
  justify-content: center;
  align-items: center;
  /* background-color: aqua; */
  color: white;
  font-size: small;
  box-shadow: 0px 5px 0px 0px rgba(0, 0, 0, 0.3);
}

.perception-map-holder {
  height: 100%;
  /* background-color: aqua; */
  width: 100%;
  gap: 10px;
  padding: 5px;
}

.perception-map-item {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 800px;
}

.map-ledger-holder {
  display: flex;
  flex-direction: column;
  width: 300px;
  height: 100%;
  /* position: absolute; */
  justify-self: end;
  padding: 20px;
  justify-content: space-around; /* background-color: red; */
}

.info-title-holder {
  min-width: 400px;
  color: white;
  height: 110px;
  background-color: aqua;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 150px;
  font-size: 3rem;
  text-transform: uppercase;
  padding: 0px 30px;
  position: relative;
  /* top: 470px; */
  box-shadow: 0px 5px 0px 0px rgba(0, 0, 0, 0.3);
}
.perception-category-name {
  font-size: x-large;
  font-weight: bold;
}
.perception-category-description {
  font-size: large;
  max-height: 200px;
  line-height: initial;
  overflow: hidden;
  overflow-y: auto;
  background-color: white;
  border-radius: 20px;
  padding: 30px;
}

@media screen and (max-width: 600px) {
  .perception-map-holder {
    padding: 5px;
  }

  .peception-ledger-holder {
    width: 40px;
    height: 64% !important;
  }
}
