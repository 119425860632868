.seemore-holder {
  background-color: rgba(39, 28, 56, 0.969);
  position: relative;
  display: flex;
  /* min-height: 100%; */
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  top: 0px;
  z-index: 7900;
}

.btn-seemore-close {
  position: relative;
  /* right: 0px; */
  top: 0px;
  /* width: 200px; */
  height: 100px;
  /* background-color: red;/ */
  z-index: 9500;
  /* margin: 20px; */
  display: flex;
  justify-content: center;
  font-size: large;
  align-items: center;
  color: white;
  text-transform: capitalize;
}
.seemore-details {
  /* padding: 80px; */
  /* gap: 10px; */
}

.seemore-icon {
  position: absolute;
  left: 100px;
  top: 50px;
  width: 200px;
  height: 200px;
  /* background-color: red; */
  border-radius: 50%;
  border: 6px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
}
