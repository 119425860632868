.main-holder {
  position: relative;
  top: 0px;
  /* background-color: red; */
  width: 100%;
  min-height: 100%;
  display: flex;
  /* justify-content: center; */
  /* gap: 50; */
  flex-direction: column;
  /* background-color: #461083; */
  align-items: center;
  z-index: 7000;
  gap: 30px;
  /* padding: 100px; */
}

.intro-text {
  /* position: absolute; */
  top: 0px;
  display: flex;
  justify-content: center;
  color: white;
  flex-direction: column;
  /* width: 1400px; */
  /* background-color: rgba(0, 0, 0, 0.258); */
  /* background-image: linear-gradient(to bottom, #461083, rgba(70, 16, 131, 0)); */
  /* background: linear-gradient(
    0deg,
    rgba(70, 16, 131, 0.2) 0%,
    rgba(70, 16, 131, 0.7) 46%,
    rgba(70, 16, 131, 1) 100%
  ); */
  background-color: #461083;
  border-radius: 20px;
  padding: 15px;
  /* top: 50px; */

  z-index: 20;
}

.feedback-text {
  /* position: absolute; */
  /* top: 0px; */
  display: flex;
  justify-content: center;
  color: white;
  flex-direction: column;
  /* width: 1400px; */
  /* background-color: rgba(0, 0, 0, 0.258); */
  /* background-image: linear-gradient(to bottom, #461083, rgba(70, 16, 131, 0)); */
  /* background: linear-gradient(
      0deg,
      rgba(70, 16, 131, 0.2) 0%,
      rgba(70, 16, 131, 0.7) 46%,
      rgba(70, 16, 131, 1) 100%
    ); */
  background-color: #461083;
  border-radius: 20px;
  padding: 15px;
  /* top: 50px; */

  z-index: 20;
}

.home-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  background: transparent url(../assets/Intro-Buildings.png) 0 0 no-repeat;
  background-size: contain;
  background-position-x: center;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.85;
  z-index: 1;
}

.home-bg::after {
  content: "";
  left: 0px;
  position: absolute;
  width: 100%;
  height: 100%;
  background: transparent url(../assets/Intro-Soundbars.png) 0 0 no-repeat;
  background-position-x: center;
  background-size: contain;
}

.navigation-holder {
  /* position: absolute; */
  /* bottom: 300px; */
  width: 100%;
  /* height: 100px; */
  /* background-color: red; */
  justify-self: center;
  display: flex;
  /* flex-direction: row; */
  justify-content: space-around;
  /* justify-content: space-around; */
  /* gap: 50px; */
  align-items: center;
  z-index: 10;
}

.nav-item-link {
  height: 120px;
  color: white;
  /* background-color: #452573; */
  /* border: 1px solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-item {
  /* background-color: blue; */
  /* padding: 10px 50px; */
  background-color: purple;
  border-radius: 20px;
  color: white;
  box-shadow: 0px 5px 0px 0px rgba(0, 0, 0, 0.3);
  text-transform: uppercase;
  position: relative;
  height: 50px;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-item-icon {
  position: absolute;
  width: 45px;
  height: 45px;
  /* background: transparent url("../assets/01 Podcast.png") 0 0 no-repeat; */
  background-size: contain;
  background-position: center;
  top: -60px;
  filter: sepia(100%) saturate(300%) brightness(70%) hue-rotate(180deg);
}

.nav-item-link a {
  color: white;
  text-decoration: none;
}

@media (min-width: 1023px) {
  .feedback-text {
    position: relative;
    margin-top: 250px;
  }
  /* big landscape tablets, laptops, and desktops */
}
