@import-normalize; /* bring in normalize.css styles */

@font-face {
  font-family: "Montserrat-Bold";
  src: local("Montserrat-Bold"),
    url("./fonts/Montserrat-Bold.otf") format("opentype");
  font-weight: bold;
}

@font-face {
  font-family: "Montserrat-Light";
  src: local("Montserrat-Light"),
    url("./fonts/Montserrat-Light.otf") format("opentype");
}

@font-face {
  font-family: "Montserrat-Medium";
  src: local("Montserrat-Medium"),
    url("./fonts/Montserrat-Medium.otf") format("opentype");
}

@font-face {
  font-family: "Montserrat-Regular";
  src: local("Montserrat-Regular"),
    url("./fonts/Montserrat-Bold.otf") format("opentype");
}

@font-face {
  font-family: "OpenSans-Bold";
  src: local("OpenSans-Bold"),
    url("./fonts/OpenSans-Bold_0.ttf") format("truetype");
}
@font-face {
  font-family: "OpenSans-Light";
  src: local("OpenSans-Light"),
    url("./fonts/OpenSans-Light_0.ttf") format("truetype");
}
@font-face {
  font-family: "OpenSans-Regular";
  src: local("OpenSans-Regular"),
    url("./fonts/OpenSans-Regular_0.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: OpenSans-Regular, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
  /* user-select: none; */
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

html,
body,
#root {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  overflow: hidden;
  color: #461083;
  /* background-color: #461083; */
  background-color: white;

  /* cursor: none; */
  user-select: none;
  overflow-y: auto;
}
