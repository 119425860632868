.quiz-left {
  display: flex;
  /* padding: 50px; */
  gap: 10px;
  flex-direction: column;
  /* justify-content: cenceter; */
  align-items: center;
  margin-top: 50px;
  width: 100%;
}

.quiz-right {
  display: flex;
  /* padding: 50px; */
  gap: 10px;
  flex-direction: column;
  /* justify-content: flex-start; */
  align-items: center;
  margin-top: 50px;
  width: 100%;
}

.quiz-intro {
  /* background-color: white; */
  position: relative;
  /* width: 100%; */
  top: 0px;
  display: flex;
  /* background: transparent url(../assets/BG-Pronounciation.png) 0 0 no-repeat; */
  /* background-color: #461083; */

  background-size: 100%;
}

.quiz-info {
  position: relative;
  width: 100%;
  /* height: 100%; */
  top: 0px;
  /* background-color: red; */
  /* background: white url(../assets/BG-Overview.png) 0 0 no-repeat; */
  /* background-color: #461083; */

  background-size: 100%;
}

.selection-instruction {
  position: relative;
  /* top: 200px; */
  /* width: 1000px; */
  /* height: 450px; */
  border-radius: 25px;
  overflow: hidden;
  background: #bc8dcb;
  color: white;
  text-align: left;
  font-weight: bold;
}

.intro-bold {
  background-color: #7030a0;
  padding: 20px;
  font-size: large;
  line-height: initial;
  /* border-radius: px; */
}

.quiz-intro .intro-try {
  font-size: large;
  padding: 10px;
}

.quiz-info .intro-try {
  font-size: large;
  padding: 10px;
}

.intro-select {
  font-size: large;
  padding: 10px;
  color: #7030a0;
}

.quiz-info .intro-select {
  font-size: 20px;
  padding: 10px;
  margin-bottom: 20px;
  color: #7030a0;
}

.selection-btns {
  position: relative;
  display: flex;
  gap: 20px;
  flex-direction: column;
  font-size: large;
  color: white;
  /* width: 100%; */
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

/* .quiz-info .selection-btns {
  position: absolute;
  top: 750px;
  display: flex;
  flex-wrap: wrap;
  width: 600px;
  height: 170px;
  justify-content: center;
  gap: 25px;
  font-size: 35px;
  color: white;
  text-transform: uppercase;
} */

.btn-quiz {
  width: 460px;
  justify-content: center;
  display: flex;
  align-items: center;
  border-radius: 50px;
  height: 70px;
  background-color: #7030a0;
  box-shadow: 0px 5px 0px 0px rgba(0, 0, 0, 0.3);
}

.quiz-info .btn-quiz {
  width: 280px;
  justify-content: center;
  display: flex;
  align-items: center;
  border-radius: 50px;
  /* height: 50px; */
  background-color: #7030a0;
  box-shadow: 0px 5px 0px 0px rgba(0, 0, 0, 0.3);
}

.quiz-info .title-holder {
  top: 200px;
  left: unset;
}

.quiz-info .quiz-right {
  align-items: center;
}
.test-holder {
  /* width: 1000px; */
  /* height: 630px; */
  position: relative;
  /* bottom: 80px; */
  /* background-color: yellow; */
  display: flex;
  gap: 30px;
  flex-direction: column;
  justify-content: space-around;
  justify-content: center;
}

.test-instructions {
  /* width: 100%; */
  /* text-align: left; */
  font-size: large;
  line-height: initial;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.custom-radio-btn-holder {
  /* height: 450px; */
  /* width: 200px; */
  border-radius: 100px;
  background: #f2f2f2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  color: white;
  font-size: small;
  gap: 15px;
}
.custom-radio-btn {
  width: 130px;
  height: 130px;
  background-color: #bc8dcb;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  font-weight: bold;
}

.answer-holder {
  display: flex;
  justify-content: space-around;
  gap: 15px;
  /* height: 470px; */
}

.test-instructions h1 {
  margin: 20px 0px;
}

.result-holder {
  display: flex;
  justify-content: space-around;
  /* height: 450px; */
  background: white url("") 0 0 no-repeat;
  width: 100%;
  background-size: 80%;
  background-position: center;
  border: 1px solid #7030a0;
  border-radius: 20px;
  padding: 20px;
  padding-top: 56.7%;
}

.test-audio-holder {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.test-audio {
  width: 90px;
  height: 65px;
  border-radius: 50%;
  background: #7030a0 url("../assets/Audio.png") 0 0 no-repeat;
  background-size: 70%;
  background-position: center 10px;
  display: flex;
  justify-content: center;
}

.test-audio-text {
  /* color: #ffffff; */
  /* position: absolute; */
  /* bottom: 50px; */
  text-transform: uppercase;
  width: 100%;
  font-size: 25px;
}
