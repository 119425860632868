.map-overview-holder {
  /* width: 100%; */
  /* height: 100%; */
  /* background-color: red; */
  /* background: white url(../../assets/BG-Overview.png) 0 0 no-repeat; */
  background-color: white;
  background-size: 100%;
  display: flex;
  flex-direction: row;
}
.map-page-right {
  display: flex;
  justify-content: center;
}

.map-page-left {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.map-page-intro {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  margin-top: 50px;
}

.map-section-holder {
  width: 100%;
  margin: 10px;
  padding-top: 75%;
  position: relative;
}

.map-holder {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  /* background-color: red; */
  background: transparent url(../../assets/Map_Podcast.png) 0 0 no-repeat;
  background-size: contain;
  background-position: center;
}

.title-holder {
  color: white;
  background-color: aqua;
  padding: 10px 20px;
  display: flex;

  /* height: 60px; */
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  font-size: x-large;
  text-transform: uppercase;

  font-weight: bold;
  box-shadow: -1px 10px 0px 0px rgba(255, 255, 255, 0.4);
}

.section-description {
  display: flex;
  border-radius: 20px;
  padding: 25px;
  text-align: left;
  color: white;
  font-size: large;
  /* line-height: 25px; */
}

.instructions {
  /* font-size: 3.5rem; */
  /* position: absolute; */
  /* bottom: 110px; */
  /* left: 100px; */
  color: #461083;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  display: flex;
}

.instructions-icon {
  width: 80px;
  height: 80px;
  margin: 0px 20px;
  /* background-color: red; */
  /* margin-left: 30px; */
  background: transparent url(../../assets/Audio.png) 0 0 no-repeat;
  background-size: 70%;
  background-position: center;
  border-radius: 50%;
}

.btn-home {
  position: absolute;
  background-color: red;
  bottom: 100px;
  left: 200px;
}

.map-nav-item {
  padding: 20px 20px;
  margin: 5px;
  /* border-radius: 50%; */
  /* background-color: blue; */
  color: white;
  width: 250px;
  font-size: large;
  height: 150px;
  position: absolute;
  display: flex;
  flex-direction: column;
  font-weight: 600;
  justify-content: center;
  align-items: center;
}

.selectable-icon {
  /* position: absolute; */
  width: 100px;
  height: 100px;
  /* border-radius: 50%; */
  /* background-color: white; */
  background: transparent url(../../assets/Audio.png) 0 0 no-repeat;
  background-size: 100%;
}

.btn-overview-home {
  position: absolute;
  width: 300px;
  left: 200px;
  background: white url(../../assets/MenuSoundLines.png) 0 0 no-repeat;
  background-position: 50px center;
  border-radius: 50px;
  border: 1px solid gray;
  height: 80px;
  bottom: 50px;
  display: flex;
  font-size: x-large;
  justify-content: center;
  text-transform: uppercase;
  align-items: center;
  box-shadow: 0px 5px 0px 0px rgba(0, 0, 0, 0.3);
}

@media screen and (max-width: 600px) {
  .selectable-icon {
    /* position: absolute; */
    width: 20px;
    height: 20px;
    /* margin-right: 20px; */
    /* border-radius: 50%; */
    /* background-color: white; */
    background: transparent url(../../assets/Audio.png) 0 0 no-repeat;
    background-size: 100%;
  }
  .map-holder-items-small {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    gap: 10px;
  }
  .map-nav-item {
    padding: 10px;
    margin: unset;
    color: white;
    width: 100%;
    font-size: small;
    height: unset;
    position: relative;
    display: flex;
    flex-direction: row;
    font-weight: 600;
    justify-content: space-around;
    align-items: center;
    background-color: #461083;
    border-radius: 20px;
  }
  .map-nav-item-noactive {
    padding: 10px;
    margin: unset;
    color: white;
    width: 100%;
    font-size: small;
    height: unset;
    position: relative;
    display: flex;
    flex-direction: column;
    font-weight: 600;
    justify-content: center;
    align-items: center;
    background-color: gray;
    opacity: 0.3;
    border-radius: 20px;
  }
}
