.details-holder {
  position: relative;
  /* width: 100%; */
  /* height: 100%; */
  /* background: transparent url(../../assets/BG-Content.png) 0 0 no-repeat; */
  /* background-color: #461083; */

  /* background-size: 100%; */
  top: 0px;
  background-color: white;
  /* display: flex; */
  /* flex-direction: column; */
  /* gap: 50px; */
}

.details-filters {
  /* background-color: red; */
  /* padding: 40px; */
  gap: 10px;
  position: relative;
  padding-top: 40px;
}
.details-info {
  /* background-color: green; */
  padding: 10px;
  gap: 10px;
  justify-content: center;
}
.home-back-navigation {
  position: fixed;
  top: 100x;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* width: 100%; */
}

.borough-holder {
  /* width: 300px; */
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: greenyellow; */
  margin: 10px;
  font-size: large;
  border-radius: 60px;
  padding: 0px 50px;
  color: white;
  text-transform: uppercase;
  box-shadow: 0px 5px 0px 0px rgba(0, 0, 0, 0.3);
}

.selected-postcode {
  position: absolute;
  width: 150px;
  height: 150px;
  background-color: purple;
  right: 0px;
  justify-content: center;
  align-items: center;
  display: none;
  font-size: xx-large;
  border-radius: 50%;
  color: white;
}

.postcode-holder {
  max-width: 100%;
  /* height: 130px; */
  overflow: hidden;
  overflow-x: auto;
  padding: 10px 0px;
  /* display: flex;
  justify-content: center; */
}
.postcode-items {
  /* background-color: blue; */
  /* margin: 10px; */
  /* flex: 1; */
  /* flex-wrap: nowrap; */
  display: flex;
  /* height: 100px; */

  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  color: white;
  font-size: x-large;
  /* overflow-x: scroll; */
  padding: 0px 50px;
}

.postcode-btn {
  width: 50px;
  min-width: 50px;
  height: 50px;

  border-radius: 50%;
  background-color: #452573;
  justify-content: center;
  align-items: center;
  display: flex;
  font-weight: bolder;
  font-size: small;
}

.themes-holder {
  width: 100%;
  /* min-height: 1px; */
  /* background-color: blue; */
  /* margin: 10px; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* justify-content: space-evenly; */
  gap: 10px;
  justify-content: center;
}

.theme-btn {
  padding: 20px 20px;
  /* margin: 10px 10px; */
  font-size: small;
  height: 50px;
  display: flex;
  color: white;
  border-radius: 50px;
  background-color: #452573;
  justify-content: center;
  align-items: center;
}

.section-holder {
  width: 100%;
  /* height: 350px; */
  /* background-color: blue; */
  /* margin: 10px; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 15px;
}

.section-btn {
  position: relative;
  padding: 10px 10px;
  /* margin: 10px 10px; */
  font-size: small;
  height: 45px;
  width: 100%;
  color: white;
  border-radius: 50px;
  background-color: #736725;
  text-transform: uppercase;
  box-shadow: 0px 5px 0px 0px rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: space-around;
  align-items: center;
  /* padding-left: 50px; */
}

.section-btn-icon {
  /* position: absolute; */
  width: 30px;
  height: 30px;
  /* background-color: red; */
  display: flex;
  justify-items: flex-start;
  align-self: flex-start;

  left: 20px;
}

.btn-seemore {
  position: relative;
  margin-top: 20px;
  /* right: 475px; */
  /* width: 200px; */
  height: 45px;
  padding: 0px 15px;
  bottom: 0px;
  background-color: #452573;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: large;
  border-radius: 10px;
}

.btn-navigation-holder {
  width: 100%;
  display: flex;
  flex-direction: row;
  /* gap: 20px; */
  justify-content: center;
  align-items: center;
  position: relaative;
  bottom: 0px;
}

.btn-nav {
  /* flex: 0.5 1; */
  display: flex;
  /* height: 70px; */
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  font-size: small;
  padding: 5px 10px;
  margin: 0 5px;
  border: 1px solid gray;
  border-radius: 10px;
  text-transform: uppercase;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.3);
}
/* .btn-details-home {
  background: white url(../../assets/MenuSoundLines.png) 0 0 no-repeat;
  background-position: 50px center;
} */

@media screen and (max-width: 600px) {
  .media-holder {
    padding: unset;
    height: 500px;
  }

  .asset-progress {
    bottom: 50px;
    right: unset;
  }

  .direction-map-toggle {
    top: -40px;
    height: 30px;
    width: 100px;
    font-size: small;
  }
}
