.App {
  position: relative;
  text-align: center;
  /* width: 100%; */
  /* height: 100%; */
  /* max-width: 1920px; */
  /* height: 100vh; */
  /* overflow: hidden; */
  /* overflow-y: auto; */
  /* background-color: #461083; */
}

/* Global */

.main-header {
  /* height: 100px; */
  width: 100%;
  /* background-color: white; */
  position: sticky;
  top: 0;
  padding: 10px 15px;
  margin: 10px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 8000;
}

.right-nav {
  justify-content: center;
  display: flex;
  flex-direction: row;
}
.home-logo {
  /* position: absolute; */
  /* bottom: 0px; */
  width: 150px;
  height: 50px;
  border-radius: 100px;
  /* bottom: 100px; */
  background: white url(./assets/main-logo.png) 0 0 no-repeat;
  background-position: center;
  background-size: 80%;
  z-index: 20;
}

.drawer-holder {
  background-color: #462573cf;
  z-index: 10000 !important;
}

.drawer-item {
  color: #452573;
  font-weight: bold !important;
}
.btn-nav-menu {
  width: 40px;
  height: 40px;
  color: white;

  background-color: #452573;
  border-radius: 50%;
  border: 1px solid white;
  /* z-index: 8000; */
  display: flex;
  justify-content: center;
  align-items: center;

  /* position: absolute; */
}

.section-content-info {
  /* background-color: white; */
  position: relative;
  /* height: 100%; */
  width: 100%;
  top: 0px;
  /* background: transparent url(./assets/BG-Content.png) 0 0 no-repeat; */
  /* background-color: #461083; */

  background-size: 100%;
}

.screen-page {
  width: 100%;
  position: relative;
  padding: 0px 10px;
}

.credits-text {
  /* color: red; */
  margin-bottom: 15px !important;
}
.credits {
  position: absolute;
  top: 0px;
  width: 100%;
  padding: 20px;
  padding-top: 80px;
  /* height: 100vh; */
  background-color: white;
  /* display: none; */
  /* background: white url("./assets/Acknowledgments V1.png") 0 0 no-repeat; */
  z-index: 7500;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.btn-close-credits {
  /* width: 200px; */
  right: 20px;
  /* top: 40px; */
  margin-bottom: 20px;
  height: 45px;
  justify-content: center;
  padding: 20px;
  align-items: center;
  display: flex;
  background-color: rgba(70, 16, 131, 0.9);
  border-radius: 10px;
  z-index: 8000;
  /* position: absolute; */
  color: white;
  font-size: large;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffffff;
  border: 1px solid #452573;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #452573;
  border-radius: 10px;
}

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
  background: #555;
} */

.main-footer {
  /* height: 100px; */
  width: 100%;
  margin-top: 50px;
  z-index: 300;
  /* background-color: rgb(198, 198, 198); */
}
