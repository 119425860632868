.media-holder {
  width: 100%;
  height: 500px;
  padding-top: 65%;
  position: relative;

  border-radius: 5%;
  background-color: #c12aa6;
  display: flex;
  justify-content: center;
}

.video-player {
  width: 100%;
}
.current-media-asset {
  position: absolute;
  top: 0px;
  height: 90%;
  width: 100%;
  /* background-color: green; */
  /* height: 630px; */
}

.details-info-title {
  width: 100%;
  /* height: 50px; */
  /* background-color: gray; */
  text-align: left;
  font-size: x-large;
}

.details-info-description {
  width: 100%;
  max-height: 150px;
  /* background-color: gray; */
  /* white-space: "pre-line"; */
  text-align: left;
  overflow: hidden;
  overflow-y: auto;
  font-size: large;
  line-height: initial;
  padding: 20px 10px;
}

.slider-navigation {
  width: 100%;
  /* height: 50px; */
  /* bottom: 45px; */
  /* position: relative; */
  padding: 0px 50px;
  display: flex;
  /* height: 100px; */
  /* background-color: gray; */
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* gap: 10px; */
  /* background-color: bisque; */
}

.asset-counter {
  font-size: 30px;
  position: relative;
  /* bottom: -10px; */
}

.asset-player-holder {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: #7030a0;
  border-radius: 5%;
  /* border: 1px solid rgb(189, 189, 189); */
}

.audio-player {
  position: absolute;
  left: -100px;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  border: 10px solid white;
  background-color: #7030a0;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #7030a0 url(../../assets/Audio.png) 0 0 no-repeat;
  background-size: 50%;
  background-position: center;
  display: none;
}

/* .media-assets {
  width: 100%;
  background-color: green;
  height: 500px;
  display: none;
} */

.current-asset-controls {
  position: absolute;
  bottom: -40px;
  /* width: 200px; */
  height: 90px;
  display: flex;
  padding: 20px;
  background-color: #c12aa6;
  justify-content: space-around;
  border-radius: 50px 50px 0px 0px;

  /* TODO : Temp */
  /* display: none; */
}
.btn-control {
  margin: 0px 5px;
  width: 60px;
  height: 60px;
  /* flex: 1; */
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: #7030a0;
  border-radius: 50%;
  color: white;
  font-size: 60px;
}

.poem-holder {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  padding: 50px 50px;
  color: white;
  line-height: 50px;
}
.slider-navigation-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-carousel-navigation {
  background-color: white;
  font-size: large;
  justify-content: center;
  align-items: center;
  display: flex;
  text-transform: capitalize;
  width: 140px;
  height: 50px;
  border-radius: 50px;
  border: 5px solid #c12aa6;
  box-shadow: 0px 5px 0px 0px rgb(0 0 0 / 30%);
}

.audio-play-btn {
  position: absolute;
  width: 200px;
  height: 200px;
  /* background-color: red; */
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 35px;
  align-items: center;
  color: white;
  font-size: 30px;
  left: 250px;
  bottom: 50px;
  /* background-color: #7030a0; */

  border-radius: 50%;
  box-shadow: 0px 5px 0px 0px rgba(0, 0, 0, 0.3);
}

.play-audio-icon {
  width: 100px;
  height: 100px;
  background: transparent url(../../assets/Audio.png) 0 0 no-repeat;
  background-size: 50%;
  background-position: center;
}
.audio-description {
  position: absolute;
  top: 0px;
  font-size: 50px;
  padding: 20px;
  color: white;
}
.map-ledger {
  display: flex;
  flex: 0.8;
}

.map-ledger-pronunciation {
  width: 100px;
  background: transparent url(../../assets/pronunciation_legend.png) 0 0
    no-repeat;
  background-size: contain;
  background-position: center;
}

.map-ledger-perception {
  width: 100px;
  background: transparent url(../../assets/perception_legend.png) 0 0 no-repeat;
  background-size: contain;
  background-position: center;
}

.map-ledger-text {
  font-size: small;
}

.peception-ledger-holder,
.pronunciation-ledger-holder {
  /* width: 50px; */
  padding: 10px;
  height: 100%;
  /* position: absolute; */
  left: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #7030a0;
}
.big-play-btn {
  color: white;
  position: absolute;
  border-radius: 50%;
  border: 4px solid white;
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.6);
}

.asset-progress {
  /* width: 150px;  */
  height: 30px;
  background-color: white;
  position: absolute;
  right: 50px;
  bottom: 10px;
  border-radius: 20px;
  display: flex;
  font-size: small;
  font-weight: bold;
  padding: 0px 25px;
  color: #7030a0;
  justify-content: center;
  align-items: center;
}

.asset-text-holder {
  position: absolute;
  min-width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  padding: 25px;
  /* padding-left: 150px; */
  padding-bottom: 100px;
  color: white;
  line-height: 40px;
  white-space: pre-line;
  font-size: large;
  text-align: left;
}

.direction-map-toggle {
  position: absolute;
  right: 0px;
  width: 120px;
  height: 45px;
  background-color: #7030a0;
  top: -100px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: small;
  text-transform: uppercase;
}

.directions-task-map {
  background: white url(../../assets/Map-Task.png) 0 0 no-repeat;

  width: 75%;
  height: 82%;
  background-size: contain;
  background-position: center;
  position: absolute;
  top: 20px;
  border-radius: 50px;
}
.map-image-holder {
  /* position: absolute; */
  width: 100%;
  height: 100%;
  /* right: -60px; */
  /* background-color: red; */
  background: transparent url() 0 -100px no-repeat;
  background-size: 90%;
  background-position: center;
}
