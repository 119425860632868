.theme-details-holder {
  /* background-color: white; */
  position: relative;
  /* width: 100%; */
  height: 100%;
  /* background: transparent url(../../assets/BG-Theme.png) 0 0 no-repeat; */
  /* background-color: #461083; */

  background-size: 100%;
  top: 0px;

  /* display: flex; */
  /* flex-direction: column; */
  /* gap: 50px; */
}

.theme-details-filters {
  /* background-color: red; */
  /* padding: 40px; */
  padding-top: 40px !important;
  gap: 5px;
  /* justify-content: space-between; */
}
.theme-details-info {
  /* background-color: green; */
  /* padding: 50px; */
  gap: 10px;
}

.btn-theme-close {
  position: relative;
  /* right: 0px; */
  top: 0px;
  /* width: 200px; */
  /* height: 100px; */
  /* background-color: red;/ */
  z-index: 9500;
  /* margin: 20px; */
  display: flex;
  justify-content: center;
  font-size: 30px;
  align-items: center;
  /* color: white; */
  text-transform: capitalize;
  margin-bottom: 70px;
}

.theme-title-holder {
  /* width: 300px; */
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: greenyellow;
  margin: 10px;
  font-size: large;
  border-radius: 100px;
  padding: 0px 20px;
  color: white;
  box-shadow: 0px 5px 0px 0px rgba(0, 0, 0, 0.3);
}

.theme-description-holder {
  width: 100%;
  max-height: 150px;
  border-radius: 10px;
  display: flex;
  font-size: 22px;
  text-align: left;
  overflow: hidden;
  overflow-y: auto;
  background-color: white;
  white-space: pre-line;
  padding: 10px;
}
.theme-section-holder {
  width: 100%;
  /* height: 350px; */
  /* background-color: blue; */
  /* margin: 10px; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 15px;
}

.theme-location {
  padding: 10px 10px;
  margin: 0px 10px;
  font-size: small;
  /* height: 50px; */
  display: flex;
  color: white;
  border-radius: 50px;
  background-color: #452573;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
}
